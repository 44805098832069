import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  createTheme,
  styled,
} from "@mui/material/";
import Paper from "@mui/material/Paper";
import { Actor } from "../../models/Actor_model";
import Loading from "../../core/components/Loading";
import Alert from "../../core/components/Alert";
import { useOutletContext } from "react-router-dom";
import WhatsappSvg from "../../core/components/WhatsappSvg";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0d6efd",
    },
  },
});

export const CustomizedSelect = styled(Select)(() => ({
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
  },
}));

export default function CategoryActor(props: any) {
  const { category } = props;

  const [apiCalling, setApiCalling] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [actors, setActors] = useState<any>([]);
  const [params, setParams] = useState<any>(null);
  const [selectedActors, setSelectedActors] = useState<any>([]);

  const [filiere] = useOutletContext<any>();

  const getData = async () => {
    setApiCalling(false);
    setHasError(false);
    Actor.fetchData(
      new URLSearchParams([
        ["filiere", filiere],
        ["category", category],
      ]),
    )
      .then((response: any) => {
        setApiCalling(false);
        setActors(Actor.fromJsonList(response.data.results));
      })
      .catch((error: any) => {
        setApiCalling(false);
        setHasError(true);
      });
  };

  useEffect(() => {
    setAllChecked(
      selectedActors &&
        selectedActors.length > 0 &&
        selectedActors.length === actors.length,
    );
  }, [selectedActors]);

  useEffect(() => {
    getData();
  }, [params, filiere]);

  const filterList = () => {
    let newParams = new URLSearchParams();
    setParams(newParams);
  };

  const selectActors = (actor?: Actor) => {
    if (!actor) {
      if (selectedActors && isChecked()) {
        setSelectedActors([]);
      } else {
        setSelectedActors(actors);
      }
    } else {
      let foundIndex = selectedActors?.findIndex((element: any) => {
        return element.id === actor.id;
      });
      if (foundIndex !== -1) {
        let tempList = [...selectedActors];
        tempList.splice(foundIndex, 1);
        setSelectedActors(tempList);
      } else {
        setSelectedActors([...selectedActors, actor]);
      }
    }
  };

  const isChecked = (actor?: Actor) => {
    if (selectedActors) {
      if (actor) {
        return selectedActors.find((element: any) => element.id === actor.id);
      } else {
        return selectedActors?.length === actors?.length;
      }
    }
    return false;
  };

  const getUserMailLists = () => {
    const mails = selectedActors.map((actor: any) => actor.email).join(",");
    return `https://mail.google.com/mail/?view=cm&fs=1&to=${mails}`;
  };

  const openWhatsapp = (num: string) => {
    num = removeFirstChar(num);
    return `https://wa.me/${num}`;
  };

  const removeFirstChar = (str: string) => {
    if (str.startsWith("+")) {
      return str.substring(1);
    } else if (str.startsWith("00")) {
      return str.substring(2);
    } else {
      return str;
    }
  };

  return (
    <div className="h-full relative">
      {apiCalling && <Loading />}
      {!apiCalling && hasError && <Alert description={"Une erreur s'est produite"} />}
      {!apiCalling && !hasError && (
        <div className="py-8 h-screen">
          {selectedActors?.length > 0 && (
            <div className="absolute top-0">
              <a
                className="px-4 py-2 fw-bold !bg-blue-900 md:!ml-2 !mt-2 md:!mt-0 rounded-md text-white"
                href={getUserMailLists()}
                target="_blank"
                rel="noreferrer"
              >
                Envoyer un mail
              </a>
            </div>
          )}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell className="font-bold">
                    <input
                      type="checkbox"
                      checked={allChecked}
                      onChange={() => selectActors()}
                    />
                  </TableCell>
                  <TableCell className="w-[40%]">
                    <span className="font-bold">Nom</span>
                  </TableCell>
                  <TableCell className="w-[40%]">
                    <span className="font-bold">Email</span>
                  </TableCell>
                  <TableCell className="w-[20%]">
                    <span className="font-bold">Contacts</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actors.map((actor: Actor) => (
                  <TableRow
                    key={actor.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <input
                        type="checkbox"
                        checked={selectedActors.some(
                          (elmt: any) => elmt.id === actor.id,
                        )}
                        onChange={() => selectActors(actor)}
                      />
                    </TableCell>
                    <TableCell>{actor.name}</TableCell>
                    <TableCell>{actor.email}</TableCell>
                    <TableCell>
                      <ul>
                        {actor.phones.map((element: string) => {
                          return (
                            <li key={element} className="flex">
                              {element}

                              {/* <a
                                href={openWhatsapp(element)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <WhatsappSvg className="w-6 h-6" />
                              </a> */}
                            </li>
                          );
                        })}
                      </ul>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}
