export default function Loading() {
  return (
    <div className="flex justify-center">
      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gradient-to-tr from-indigo-500 to-pink-500 animate-spin">
        <div className="h-5 w-5 rounded-full bg-gray-200"></div>
      </div>
      <button type="button" className="p-2" disabled>
        Chargement en cours...
      </button>
    </div>
  );
}
