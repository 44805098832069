import React, { useState, useEffect, Component } from "react";
import { ListGroup } from "react-bootstrap";
import LibraryCard from "../../core/components/card/card";
import DocumentFile from "../../models/Document";
import Select from "@mui/material/Select";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
  createTheme,
  styled,
} from "@mui/material/";
import MapModel from "../../models/Map";
import { useOutletContext } from "react-router-dom";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0d6efd",
    },
  },
});

export const CustomizedSelect = styled(Select)(() => ({
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
  },
}));

export default function MapPage(props: any) {
  const [documents, setDocuments] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [filiere] = useOutletContext<any>();

  const fetchDocuments = () => {
    setLoading(true);
    const params = new URLSearchParams();
    if (searchText) {
      params.append("search", searchText);
    }
    params.append("filiere", filiere);
    MapModel.fetchData(params).then((response: any) => {
      setDocuments(MapModel.fromJsonList(response.data.results));
      setLoading(false);
    });
  };

  useEffect(() => {
    // We only fetch documents automatically when searchText is empty
    if (!searchText) {
      fetchDocuments();
    }
  }, [searchText, filiere]);

  return (
    <ThemeProvider theme={theme}>
      <div className="pt-[67px] h-screen overflow-y-auto">
        <div
          className="filter-bar mx-5 mt-4 p-3 text-center"
          style={{
            display: "grid",
            // gridTemplateColumns: "repeat(2,1fr)",
            gap: "20px",
            backgroundColor: "rgba(51, 153, 255, 0.04)",
            borderRadius: "15px",
          }}
        >
          <div className="w-full sm:flex m-auto md:!w-1/2">
            <TextField
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
              size="small"
              color="primary"
              InputProps={{
                name: "InputProps",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearchText("")} edge="end">
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              className="md:!w-3/4 w-full searchInput"
            />
            <Button
              variant="contained"
              className="fw-bold !bg-blue-900 md:!ml-2 !mt-2 md:!mt-0"
              onClick={fetchDocuments}
            >
              Rechercher
            </Button>
          </div>
        </div>
        <ListGroup
          className={`m-5 pt-5 md:flex md:flex-row md:flex-wrap gap-4 ${
            loading ? "hidden" : ""
          }`}
        >
          {documents.map((doc) => (
            <div className="flex justify-center mb-2 md:mb-0">
              <LibraryCard
                key={doc.pk}
                cardData={doc}
                withAction={false}
                baseUrl="maps"
                full
              />
            </div>
          ))}
        </ListGroup>
      </div>
    </ThemeProvider>
  );
}
