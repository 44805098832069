import getAxiosConfig from "./axios";
import axios from "./axios";

const axiosInstance = getAxiosConfig();
export const getFromApiFunction = async (
  endPoint: string,
  params: URLSearchParams = new URLSearchParams(),
  headers: any = {},
) => axiosInstance.get(endPoint, { params, ...headers });

export const createDataFunction = async (endPoint: string, payload: any) =>
  axios().post(endPoint, payload);

export const updateDataFunction = async (endPoint: string, payload: any) =>
  axios().put(endPoint, payload);

export const deleteDataFunction = async (endPoint: string, payload: any) =>
  axios().delete(endPoint, payload);
