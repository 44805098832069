import BaseModel from "./BaseModel";

export default class Intervention extends BaseModel {
  short_name: string;

  constructor(id: number, name: string, short_name: string) {
    super(id, name);
    this.id = id;
    this.name = name;
    this.short_name = short_name;
  }

  static fromJson(json: any): BaseModel {
    return new this(json.id, json.name, json.short_name);
  }

  static toSelectOption(values: Intervention[]): any {
    return values.map((value) => {
      return {
        value: value.id,
        label: value.short_name || value.name,
      };
    });
  }
}
