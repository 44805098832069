import * as React from "react";

export default function StreetViewSvg(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={800}
      height={800}
      className="si-glyph si-glyph-map-3"
      viewBox="0 -0.5 17 17"
      {...props}
    >
      <title>{"1156"}</title>
      <g fill="#434343" fillRule="evenodd">
        <path
          d="m11.035 13.581 4.962 1.294V3.415l-4.962-1.353v11.519ZM6.021 14.965l3.922-1.384V2.062L6.021 3.879v11.086ZM.042 13.118l4.95 1.847V3.879L.042 2.031v11.087Z"
          className="si-glyph-fill"
        />
      </g>
    </svg>
  );
}
