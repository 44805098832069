import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Filler,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  BarController,
  CategoryScale,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
} from "chart.js";
import BarChart from "./graph_components/BarChart";
// import BarChart from "./BarChart";
// import {
//   CATEGORIES_CHART,
//   CVA_CHARTS,
//   MALNUTRITION_CHART,
//   STAKE_HOLDER_CHART,
//   departementsIntervention,
// } from "../../constants";

ChartJS.register(
  LinearScale,
  RadialLinearScale,
  CategoryScale,
  BarElement,
  ArcElement,
  PointElement,
  BarController,
  LineElement,
  RadarController,
  PolarAreaController,
  Filler,
  Tooltip,
  Legend,
);

const scrollBarClasses =
  // "scrollbar-thin scrollbar-thumb-slate-500 scrollbar-track-slate-50 overflow-auto";
  "scrollbar scrollbar-thumb-w-[2px] scrollbar-thumb-rounded-full scrollbar-thumb-gray-400 scrollbar-track-gray-100 flex-grow sm:overflow-auto";

export default function ChartRenderer(props: any) {
  /**
   * In the following base code:
   * - divisionType is a specific type of territory division (departments or poles)
   * - division is a specific division of a given type (Ex: Alibori for departments and PDA1 for poles)
   */

  const {
    filterParams,
    selectedDivTypeName,
    statsUnitProps,
    buildParameter,
    intervention,
  } = props;

  const [value, setValue] = React.useState(0);

  const [indics, setIndics]: any = React.useState([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [statsUnit, setStatsUnit] = useState(statsUnitProps);

  useEffect(() => {
    setStatsUnit(statsUnitProps);
  }, [statsUnitProps]);

  useEffect(() => {}, [statsUnit]);

  const width = "relative w-[250px] sm:w-[450px] mb-8 m-auto 2xl:mx-[10px]";

  return (
    <div className={`${scrollBarClasses} h-full align-center`}>
      <h1 className="text-center font-bold"> TABLEAU DE BORD DES INTERVENTIONS </h1>
      <h1 className="text-center font-bold mb-8 sm:w-1/2 m-auto">
        {intervention && intervention.name}
      </h1>
      <div className="sm:grid xl:grid-cols-2 2xl:grid-rows-2 gap-4 w-fit m-auto rounded-md">
        <div className={width}>
          <BarChart
            graphName="Nombre d'interventions dérivées"
            baseEndpoint="interventions/stats/"
            statsUnitProps={statsUnit}
            paramsProps={filterParams}
            buildParameter={buildParameter}
            color={"#ebab34"}
          />
        </div>

        <div className={width}>
          <BarChart
            graphName="Nombre de projets"
            baseEndpoint="projects/stats/"
            statsUnitProps={statsUnit}
            paramsProps={filterParams}
            buildParameter={buildParameter}
            color={"#cfc5b4"}
          />
        </div>

        <div className={width}>
          <BarChart
            graphName="Nombre d'activités"
            baseEndpoint="activities/stats/"
            statsUnitProps={statsUnit}
            paramsProps={filterParams}
            buildParameter={buildParameter}
            color={"#ed5f7b"}
          />
        </div>

        <div className={width}>
          <BarChart
            graphName="Nombre de villages touchés"
            baseEndpoint="villages/stats/"
            statsUnitProps={statsUnit}
            paramsProps={filterParams}
            buildParameter={buildParameter}
            color={"#595dc2"}
          />
        </div>

        <div className={width}>
          <BarChart
            graphName="nombre de villages bénéficiaires d'appui de 2 projets au moins-"
            baseEndpoint="villages_two_projects/stats/"
            statsUnitProps={statsUnit}
            paramsProps={filterParams}
            buildParameter={buildParameter}
            color={"#875b1a"}
          />
        </div>
      </div>
    </div>
  );
}
