export default class Module {
  label: string;
  link: string;
  icon?: any;
  hasChildren?: boolean;
  children?: Module[];

  constructor(
    label: string,
    link: string,
    icon?: any,
    hasChildren: boolean = false,
    children: Module[] = [],
  ) {
    this.label = label;
    this.link = link;
    this.icon = icon;
    this.hasChildren = hasChildren;
    this.children = children;
  }

  isSelected(currentRoute: string): boolean {
    return currentRoute === this.link;
  }
}
