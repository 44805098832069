import L from "leaflet";
import country from "../shapes/departement.json";

export const ICON_MARKER_AMENAGEMENT = L.divIcon({
  className: "custom-div-icon",
  html: "<div style='background-color:#c30b82;' class='marker-pin'></div><i class='material-icons'><i class='fa-solid fa-tree'></i></i>",
  iconSize: [30, 42],
  iconAnchor: [15, 42],
});

export const ICON_MARKER_RETENU_EAU = L.divIcon({
  className: "custom-div-icon",
  html: "<div style='background-color:#ff8a80;' class='marker-pin'></div><i class='material-icons'><i class='fa-solid fa-water'></i></i>",
  iconSize: [30, 42],
  iconAnchor: [15, 42],
});

export const ICON_MARKER_BOUTIQUE = L.divIcon({
  className: "custom-div-icon",
  html: "<div style='background-color:#9b0000;' class='marker-pin'></div><i class='material-icons'><i class='fa-solid fa-shop'></i></i>",
  iconSize: [30, 42],
  iconAnchor: [15, 42],
});

export const ICON_MARKER_MAGASIN = L.divIcon({
  className: "custom-div-icon",
  html: "<div style='background-color:#6200ea;' class='marker-pin'></div><i class='material-icons'><i class='fa-solid fa-store'></i></i>",
  iconSize: [30, 42],
  iconAnchor: [15, 42],
});

export const defaultBound = L.geoJSON(country).getBounds();
