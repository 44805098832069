/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  LayersControl,
  FeatureGroup,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import country from "../../shapes/departement.json";
import { MAP_VIEWS, Layers } from "../../core/constants";
import { API_URL } from "../../services/api/axios";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import ZoomControlWithReset from "./ZoomWithReset";
import { getMarkers, retrieveMarker } from "../../services/api/data/layer_services";
import { downloadFile } from "../../core/components/Navbar";

/* Note: DO NOT DELETE THIS COMMENT
When using local copy of leaflet css markers are not displayed properly
Following code is meant to solve the issue.
See https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387
*/
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const DEFAULT_MARKER_PER_CONTROL_LAYER = [
  {
    layerControlId: 1,
    markers: [],
  },
  {
    layerControlId: 2,
    markers: [],
  },
];

export function PopupComponent(props) {
  const { marker, layerControl } = props;

  const [apiCalling, setApiCalling] = useState(true);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    setApiCalling(true);
    setHasError(false);
    // retrieveMarker(marker.id)
    //   .then((requestResponse) => {
    //     let res = requestResponse.data;
    //     setData(res);
    //     setApiCalling(false);
    //     setHasError(false);
    //     // retrieveMarker(marker.id).then((requestResponse) => {
    //     //   let res = requestResponse.data;
    //     //   setData(res);
    //     //   setApiCalling(false);
    //     //   setHasError(false);
    //     // }).catch((error) => {
    //     //   setApiCalling(false);
    //     //   setHasError(true);
    //     // });
    //   });
  }, []);
  const map = useMap();
  return (
    <Popup>
      <div
        style={{
          display: "grid",
          gap: "3px",
        }}
      >
        <button
          onClick={() => map.flyTo([marker.latitude, marker.longitude], 18)}
          className="mb-4 border-2 border-black rounded-md p-2 bg-gray-200 hover:bg-gray-300 hover:border-gray-400"
        >
          Zoomer
        </button>
        <hr className="border border-purple-200" />

        <div>
          <span className="font-bold">Nom du village: &nbsp;</span>
          {marker.name}
        </div>
        <div className="w-full">
          <div className="w-full max-w-md rounded-2xl">
            {marker &&
              marker.projects_list &&
              marker.projects_list.map((project, index) => {
                return <div>{project.name}</div>;
              })}
          </div>
        </div>
        <hr className="border border-purple-200" />
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              downloadFile(
                `villages/${marker.id}/report/`,
                `Village-${marker.name}.pdf`,
                "",
              )
            }
            className="cursor-pointer"
          >
            Télécharger la fiche
          </a>
        </div>
      </div>
    </Popup>
  );
}

function MappingMarker() {
  const bounds = L.geoJSON(country).getBounds();
  const [view, setView] = useState(MAP_VIEWS[0]);
  const [tileKey, setTileKey] = useState(MAP_VIEWS[0].id);
  const [apiCalling, setApiCalling] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [mapMarkers, setMapMarkers] = useState([]);

  const didMount1 = useRef(false);
  const didMount2 = useRef(false);

  useEffect(() => {
    setApiCalling(true);
    setHasError(false);
    getMarkers("/villages/").then((requestResponse) => {
      let res = requestResponse.data.results;
      setMapMarkers(res);
      setApiCalling(false);
      setHasError(false);
    });
  }, []);

  useEffect(() => {}, [mapMarkers]);

  const testIt = () => {
    setMapMarkers(["deWWWd"]);
  };

  return (
    <>
      {mapMarkers &&
        mapMarkers.map((marker, index) => {
          return <div></div>;
        })}
      <div
        className="w-full h-full"
        style={{
          display: "grid",
        }}
      >
        <div
          className="border-4 border-white w-full h-full rounded-3xl"
          style={{
            display: "grid",
          }}
        >
          <MapContainer bounds={bounds} zoomControl={false}>
            <ZoomControlWithReset bounds={bounds} />
            <LayersControl position="topright">
              {Layers.map((layer) => (
                <LayersControl.Overlay key={layer.name} name={layer.name}>
                  {layer.name === "Départements d'intervention" ? (
                    <FeatureGroup>
                      {layer.layer.features.map((feature) => {
                        let dataJson = feature;
                        return (
                          <FeatureGroup>
                            {<GeoJSON data={dataJson} style={{ color: "green" }} />}
                            <Popup>
                              <div
                                style={{
                                  gap: "3px",
                                }}
                              >
                                <div className="d-flex justify-content-center font-bold">
                                  <div> {dataJson.properties.Nom} </div>
                                </div>
                                Télécharger la{" "}
                                <a
                                  href={`${API_URL}departements/${dataJson.properties.Nom.toUpperCase()}/fiche/`}
                                  target="_blank"
                                >
                                  fiche
                                </a>
                              </div>
                            </Popup>
                          </FeatureGroup>
                        );
                      })}
                    </FeatureGroup>
                  ) : (
                    <GeoJSON data={layer.layer} style={layer.style || {}} />
                  )}
                </LayersControl.Overlay>
              ))}
              {!apiCalling && !hasError && (
                <LayersControl.Overlay name={"Village"}>
                  <FeatureGroup>
                    {mapMarkers &&
                      mapMarkers.map((marker, index) => {
                        const markerkey = `${index}`;
                        if (marker && marker.latitude && marker.longitude) {
                          return (
                            <Marker
                              key={markerkey}
                              position={[marker.latitude, marker.longitude]}
                            >
                              <div key={`popup-${markerkey}`}>
                                <PopupComponent marker={marker} />
                              </div>
                            </Marker>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </FeatureGroup>
                </LayersControl.Overlay>
              )}
            </LayersControl>
            <TileLayer key={tileKey} {...view.tileLayerInfos} />
          </MapContainer>
        </div>
      </div>
    </>
  );
}

export default MappingMarker;
