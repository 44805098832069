import React, { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import CategoryReportPage from "./CategoryReportPage";

export default function ReportPage(props: any) {
  enum Category {
    TEMPLATE = "Modèles",
    REPORT = "Rapports",
    ATLAS = "ATLAS",
  }

  const [value, setValue] = useState("0");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="pt-[67px] max-w-[1400px] mx-auto my-0 px-4 md:px-10 flex items-center justify-between">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {Object.values(Category).map((category: any, index) => {
                return (
                  <Tab
                    label={category}
                    key={category}
                    value={index.toString()}
                    className="!text-[11px] sm:!text-sm"
                  />
                );
              })}
            </TabList>
          </Box>
          <>
            {Object.keys(Category).map((category, index) => {
              return (
                <TabPanel value={index.toString()} className="!px-0 !py-6">
                  <CategoryReportPage category={category.toLowerCase()} />
                </TabPanel>
              );
            })}
          </>
        </TabContext>
      </Box>
    </div>
  );
}
