import { MAP_VIEWS } from "../constants";

export default function ModalSupperposition(props: any) {
  const { isOpen, onClose } = props;

  return (
    <div
      className={`overlay-inner absolute right-[40px] top-[150px] ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div
        className="lc-modal absolute right-0 top-0 w-[200px] sm:w-[200px] bg-black/50 p-5 z-[999] transition-pacofide"
        data-lc-target="overlay"
      >
        <button
          className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-white text-2xl z-[2] cursor-pointer rounded-full transition-pacofide"
          data-lc-target="#overlay"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style={{ fill: "currentColor" }}
          >
            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
          </svg>
        </button>
        <div className="space-y-12 max-h-[564px]">
          <div className="relative text-white">
            <h3 className="flex items-center justify-center space-x-4 text-base font-medium">
              Superpositions
            </h3>
            <form className="mt-6">
              <div className="grid grid-cols-2 gap-4 sm:grid-cols-2">
                {MAP_VIEWS.map((view, index) => {
                  return (
                    <div className="" key={index}>
                      <input
                        id={view.id}
                        className="hidden peer/limites"
                        type="radio"
                        name="overlay"
                        checked={view.id === "limites"}
                        onChange={() => props.action(index)}
                      />
                      <label
                        htmlFor={view.id}
                        className="flex flex-col items-center justify-center p-2 space-x-2 bg-white rounded-md cursor-pointer peer-checked/limites:bg-blue-200 transition-pacofide"
                      >
                        <span className="flex items-center justify-center w-8 h-8 rounded-full">
                          {view.component}
                        </span>
                        <span
                          className={
                            "text-xs text-black " +
                            (props.currentIndex === index ? "font-bold" : "")
                          }
                        >
                          {view.viewName}
                        </span>
                      </label>
                    </div>
                  );
                })}
                {/* <div className="">
                                    <input
                                        id="limites"
                                        className="hidden peer/limites"
                                        type="radio"
                                        name="overlay"
                                        checked
                                    />
                                    <label
                                        htmlFor="limites"
                                        className="flex flex-col items-center justify-center p-2 space-x-2 bg-white rounded-md cursor-pointer peer-checked/limites:bg-blue-200 transition-pacofide"
                                    >
                                        <span
                                            className="flex items-center justify-center w-8 h-8 rounded-full"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                style={{ fill: "currentColor" }}
                                                className="w-5 h-5 text-blue-500"
                                            >
                                                <path
                                                    d="M6 21H3a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v17a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1z"
                                                ></path>
                                            </svg>
                                        </span>
                                        <span className="text-xs text-black"
                                        >Limites</span
                                        >
                                    </label>
                                </div> */}
                {/* <div className="">
                                    <input
                                        id="routes"
                                        className="hidden peer/routes"
                                        type="radio"
                                        name="overlay"
                                    />
                                    <label
                                        htmlFor="routes"
                                        className="flex flex-col items-center justify-center p-2 space-x-2 bg-white rounded-md cursor-pointer peer-checked/routes:bg-blue-200 transition-pacofide"
                                    >
                                        <span
                                            className="flex items-center justify-center w-8 h-8 rounded-full"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                style={{ fill: "currentColor" }}
                                                className="w-5 h-5 text-blue-500"
                                            >
                                                <path
                                                    d="M13 2.051V11h8.949c-.47-4.717-4.232-8.479-8.949-8.949zm4.969 17.953c2.189-1.637 3.694-4.14 3.98-7.004h-8.183l4.203 7.004z"
                                                ></path>
                                                <path
                                                    d="M11 12V2.051C5.954 2.555 2 6.824 2 12c0 5.514 4.486 10 10 10a9.93 9.93 0 0 0 4.255-.964s-5.253-8.915-5.254-9.031A.02.02 0 0 0 11 12z"
                                                ></path>
                                            </svg>
                                        </span>
                                        <span className="text-xs text-black"
                                        >Routes</span
                                        >
                                    </label>
                                </div>
                                <div className="">
                                    <input
                                        id="rivieres"
                                        className="hidden peer/rivieres"
                                        type="radio"
                                        name="overlay"
                                    />
                                    <label
                                        htmlFor="rivieres"
                                        className="flex flex-col items-center justify-center p-2 space-x-2 bg-white rounded-md cursor-pointer peer-checked/rivieres:bg-blue-200 transition-pacofide"
                                    >
                                        <span
                                            className="flex items-center justify-center w-8 h-8 rounded-full"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                style={{ fill: "currentColor" }}
                                                className="w-5 h-5 text-blue-500"
                                            >
                                                <path
                                                    d="M19.649 5.286 14 8.548V2.025h-4v6.523L4.351 5.286l-2 3.465 5.648 3.261-5.648 3.261 2 3.465L10 15.477V22h4v-6.523l5.649 3.261 2-3.465-5.648-3.261 5.648-3.261z"
                                                ></path>
                                            </svg>
                                        </span>
                                        <span className="text-xs text-black"
                                        >Rivières</span
                                        >
                                    </label>
                                </div>
                                <div className="">
                                    <input
                                        id="feux"
                                        className="hidden peer/feux"
                                        type="radio"
                                        name="overlay"
                                    />
                                    <label
                                        htmlFor="feux"
                                        className="flex flex-col items-center justify-center p-2 space-x-2 bg-white rounded-md cursor-pointer peer-checked/feux:bg-blue-200 transition-pacofide"
                                    >
                                        <span
                                            className="flex items-center justify-center w-8 h-8 rounded-full"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                style={{ fill: "currentColor" }}
                                                className="w-5 h-5 text-blue-500"
                                            >
                                                <path
                                                    d="M11 19.91 10 22h4l-1-2.09c4-.65 7-5.28 7-9.91a8 8 0 0 0-16 0c0 4.63 3.08 9.26 7 9.91zm1-15.66v1.5A4.26 4.26 0 0 0 7.75 10h-1.5A5.76 5.76 0 0 1 12 4.25z"
                                                ></path>
                                            </svg>
                                        </span>
                                        <span className="text-xs text-black"
                                        >Feux</span
                                        >
                                    </label>
                                </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
