import BarChart from "../graphs/graph_components/BarChart";
import { Carousel } from "antd";
import { useNavigate, useOutletContext } from "react-router-dom";

export default function Home() {
  const [filiere, intervention, setFiliere] = useOutletContext<any>();
  const navigate = useNavigate();

  const moveToMapping = (filiereConst: any) => {
    setFiliere(filiereConst);
    navigate("/mapping");
  };

  return (
    <div className="home-background h-screen">
      <div className="max-w-[1400px] mx-auto my-0 h-full">
        <div className="pt-2 flex justify-center gap-2 items-center sm:flex-row flex-col">
          <span className="w-[200px] lg:w-auto">
            <img src="./assets/logo.jpeg" />
          </span>
          <span className="max-w-[200px] w-[200px] !h-[67px] lg:!h-[120px] lg:w-auto bg-white wb-logo">
            <img src="./assets/world-bank.png" />
          </span>
        </div>
        <div className="flex pt-12 custom-header">
          <h2 className="w-ful mx-auto my-0 text-center font-light text-xl font-normal tracking-[2px] sm:tracking-[5px] text-slate-400 color-green !text-lg sm:!leading-10 sm:!text-[2rem] md:!leading-[2.75rem] md:!text-[2.5rem] custom-title">
            PLATEFORME DE GOUVERNANCE TERRITORIALE
            <br />
            DES INTERVENTIONS DES FILIERES AGRICOLES DU
            <span className="font-semibold"> PACOFIDE</span>
          </h2>
        </div>
        <div className="mt-12 mb-28 max-w-[80rem] bg-white rounded-t-lg block m-auto">
          <div className="">
            {/* <Carousel> */}
            <div className="sm:space-y-12 max-w-[70rem] mx-auto my-0 h-full">
              <div className="flex items-center justify-center space-x-9 p-7 lg:p-0">
                <div className="flex flex-col lg:flex-row items-center gap-4 lg:gap-0 lg:space-x-10">
                  <button
                    type="button"
                    className="w-full sm:max-w-[100px] bg-green-500 shadow-md cursor-default text-white text-sm font-medium sm:w-36 text-center py-2.5 rounded-full cursor-pointer"
                    onClick={() => {
                      moveToMapping("pineapple");
                    }}
                  >
                    Ananas
                  </button>
                  <Carousel className="h-[200px] w-[350px] sm:w-[450px]">
                    <img
                      className="h-[200px] w-[400px] object-cover rounded-md shadow-lg shadow-black/30"
                      src="./assets/ananas.jpg"
                      alt=""
                    />
                    <div className="flex items-center h-[200px]">
                      <span className="w-[450px] !h-[200px] rounded-md shadow-lg shadow-black/30 filiere-text">
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy
                        text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                      </span>
                    </div>
                  </Carousel>
                  <span className="w-[500px] hidden sm:block">
                    <BarChart
                      graphName="Production"
                      baseEndpoint="production/stats/"
                      statsUnitProps={"year"}
                      paramsProps={new URLSearchParams([["filiere", "pineapple"]])}
                      buildParameter={() => {}}
                      color={"#ebab34"}
                      customHeigth={200}
                    />
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-center space-x-9 p-7 lg:p-0">
                <div className="flex flex-col lg:flex-row items-center gap-4 lg:gap-0 lg:space-x-10">
                  <button
                    type="button"
                    className="w-full sm:max-w-[100px] bg-pink-500 shadow-md cursor-default text-white text-sm font-medium sm:w-36 text-center py-2.5 rounded-full cursor-pointer"
                    onClick={() => {
                      moveToMapping("cashew");
                    }}
                  >
                    Anacarde
                  </button>
                  <Carousel className="h-[200px] w-[350px] sm:w-[450px]">
                    <img
                      className="h-[200px] w-[400px] object-cover rounded-md shadow-lg shadow-black/30"
                      src="./assets/anacarde.jpg"
                      alt=""
                    />
                    <div className="flex items-center h-[200px]">
                      <span className="w-[450px] !h-[200px] rounded-md shadow-lg shadow-black/30 filiere-text">
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy
                        text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                      </span>
                    </div>
                  </Carousel>
                  <span className="w-[500px] hidden sm:block">
                    <BarChart
                      graphName="Production"
                      baseEndpoint="production/stats/"
                      statsUnitProps={"year"}
                      paramsProps={new URLSearchParams([["filiere", "cashew"]])}
                      buildParameter={() => {}}
                      color={"#ebab34"}
                      customHeigth={200}
                    />
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="space-y-12 max-w-[70rem] mx-auto my-0 h-full">
                <div className="flex items-center space-x-9">
                  <div className="flex items-center space-x-10">
                    <button
                      type="button"
                      className="bg-green-500 shadow-md cursor-default text-white text-sm font-medium w-36 text-center py-2.5 rounded-full"
                      onClick={() => {
                        moveToMapping("pineapple");
                      }}
                    >
                      Ananas
                    </button>
                    <Carousel autoplay className="h-[200px] w-[450px]">
                      <img
                        className="h-[200px] w-[400px] object-cover rounded-md shadow-lg shadow-black/30"
                        src="./assets/ananas.jpg"
                        alt=""
                      />
                      <div className="flex items-center h-[200px]">
                        <span className="w-[450px] !h-[200px] rounded-md shadow-lg shadow-black/30 filiere-text">
                          Lorem Ipsum is simply dummy text of the printing and typesetting
                          industry. Lorem Ipsum has been the industry's standard dummy text
                          ever since the 1500s, when an unknown printer took a galley of
                          type and scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap into
                          electronic typesetting, remaining essentially unchanged.
                        </span>
                      </div>
                    </Carousel>
                    <span className="w-[500px]">
                      <BarChart
                        graphName="Production"
                        baseEndpoint="production/stats/"
                        statsUnitProps={"year"}
                        paramsProps={{ filiere: "pineapple" }}
                        buildParameter={() => { }}
                        color={"#ebab34"}
                        customHeigth={200}
                      />
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-9">
                  <div className="flex items-center space-x-10">
                    <button
                      type="button"
                      className="bg-pink-500 shadow-md cursor-default text-white text-sm font-medium w-36 text-center py-2.5 rounded-full"
                      onClick={() => {
                        moveToMapping("cashew");
                      }}
                    >
                      Anacarde
                    </button>
                    <Carousel autoplay className="h-[200px] w-[450px]">
                      <img
                        className="h-[200px] w-[400px] object-cover rounded-md shadow-lg shadow-black/30"
                        src="./assets/anacarde.jpg"
                        alt=""
                      />
                      <div className="flex items-center h-[200px]">
                        <span className="w-[450px] !h-[200px] rounded-md shadow-lg shadow-black/30 filiere-text">
                          Lorem Ipsum is simply dummy text of the printing and typesetting
                          industry. Lorem Ipsum has been the industry's standard dummy text
                          ever since the 1500s, when an unknown printer took a galley of
                          type and scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap into
                          electronic typesetting, remaining essentially unchanged.
                        </span>
                      </div>
                    </Carousel>
                    <span className="w-[500px]">
                      <BarChart
                        graphName="Production"
                        baseEndpoint="production/stats/"
                        statsUnitProps={"year"}
                        paramsProps={{ filiere: "cashew" }}
                        buildParameter={() => { }}
                        color={"#ebab34"}
                        customHeigth={200}
                      />
                    </span>
                  </div>
                </div>
              </div> */}
            {/* </Carousel> */}
          </div>
        </div>
      </div>
    </div>
  );
}
