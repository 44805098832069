import React from "react";
import ReactDOM from "react-dom/client";
// import App from './App.tsx';
import "./index.css";
// import "./core/styling/output.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RouteErrorPage from "./views/RouteErrorPage";
import ReportPage from "./views/reportpage/ReportPage";
import Mapping from "./views/mapping/Mapping";
import Dashboard from "./views/dashboard/Dashboard";
import HomePage from "./views/homepage/HomePage";
import LoginForm from "./views/Login/LoginForm";
import MapLibrary from "./views/maplibrary/MapLibrary";

import {
  MapContainer,
  TileLayer,
  GeoJSON,
  LayersControl,
  FeatureGroup,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import { Provider } from "react-redux";
import store from "./services/api/auth/store";
import { ToastContainer } from "react-toastify";
import MappingMarker from "./views/mapping/MappingMarker";
import MainPage from "./views/UniquePage/MainPage";
import Graph from "./views/graphs/Graph";
import MapPage from "./views/maplibrary/MapPage";
import Home from "./views/home/Home";
import ActorsView from "./views/actors/ActorsPage";
import FileLoader from "./views/graphs/Loader";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    errorElement: <RouteErrorPage />,
    children: [
      {
        path: "/mapping",
        element: <MainPage />,
      },
      {
        path: "/mappingmarker",
        element: <MappingMarker />,
      },
      {
        path: "/reports",
        element: <ReportPage />,
      },
      {
        path: "/maplibrary",
        element: <MapPage />,
      },
      {
        path: "/graph",
        element: <Graph />,
      },
      {
        path: "/actors",
        element: <ActorsView />,
      },
      {
        path: "/",
        element: <Home />,
      },
    ],
  },

  {
    path: "/login",
    element: <LoginForm />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  //   <Provider store={store}>
  //     <div className="bg-primary-light w-full h-full">
  //       <RouterProvider router={router} />
  //     </div>
  //     <ToastContainer />
  //   </Provider>
  // </React.StrictMode>,
  <Provider store={store}>
    <div className="bg-primary-light w-full h-full">
      <RouterProvider router={router} />
    </div>
    <ToastContainer />
  </Provider>,
);
