/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  LayersControl,
  FeatureGroup,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import country from "../../shapes/departement.json";
import { MAP_VIEWS, Layers } from "../../core/constants";
import { API_URL } from "../../services/api/axios";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import ZoomControlWithReset from "./ZoomWithReset";
import { getMarkers, retrieveMarker } from "../../services/api/data/layer_services";
import { downloadFile } from "../../core/components/Navbar";

/* Note: DO NOT DELETE THIS COMMENT
When using local copy of leaflet css markers are not displayed properly
Following code is meant to solve the issue.
See https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387
*/
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const DEFAULT_MARKER_PER_CONTROL_LAYER = [
  {
    layerControlId: 1,
    markers: [],
  },
  {
    layerControlId: 2,
    markers: [],
  },
];

export function PopupAreaComponent(props) {
  const { dataJson, fileName, downloadLink } = props;

  const [apiCalling, setApiCalling] = useState(true);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    setApiCalling(true);
    setHasError(false);
    // retrieveMarker(marker.id)
    //   .then((requestResponse) => {
    //     let res = requestResponse.data;
    //     setData(res);
    //     setApiCalling(false);
    //     setHasError(false);
    //     // retrieveMarker(marker.id).then((requestResponse) => {
    //     //   let res = requestResponse.data;
    //     //   setData(res);
    //     //   setApiCalling(false);
    //     //   setHasError(false);
    //     // }).catch((error) => {
    //     //   setApiCalling(false);
    //     //   setHasError(true);
    //     // });
    //   });
  }, []);
  const map = useMap();
  return (
    <Popup>
      <div
        style={{
          gap: "3px",
        }}
      >
        <div className="d-flex justify-content-center font-bold">
          <div> {dataJson.name} </div>
        </div>
        <hr className="border border-purple-200" />
        <div className="w-full">
          <div className="w-full max-w-md rounded-2xl">
            {dataJson &&
              dataJson.projects_list &&
              dataJson.projects_list.map((project, index) => {
                return <div>{project.name}</div>;
              })}
          </div>
        </div>
        <hr className="border border-purple-200" />
        Télécharger la{" "}
        <a
          href="#"
          onClick={() => downloadFile(downloadLink, fileName, "")}
          className="cursor-pointer"
        >
          fiche
        </a>
      </div>
    </Popup>
  );
}

export default PopupAreaComponent;
