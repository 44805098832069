import { getFromApiFunction } from "../apiFunctions";

const getLayers = async (params: URLSearchParams) => {
  const endPoint = "/maps/";
  return getFromApiFunction(endPoint, params);
};

const retrieveLayer = async (id: any) => {
  const endPoint = `/maps/${id}/`;
  return getFromApiFunction(endPoint);
};

const getMarkers = async (endPoint: string, params: URLSearchParams) => {
  return getFromApiFunction(endPoint, params);
};

const retrieveMarker = async (id: any) => {
  const endPoint = `/villages/${id}/`;
  return getFromApiFunction(endPoint);
};

export { getLayers, retrieveLayer, getMarkers, retrieveMarker };
