import { getFromApiFunction } from "../apiFunctions";

export const dowloadDocument = async (url: string, filename: string) => {
  const params = new URLSearchParams();
  getFromApiFunction(url, params, {
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {});
};
