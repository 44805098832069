import * as React from "react";

export default function CashewSVG(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={44}
      height={44}
      viewBox="0 0 24 24"
      {...props}
    >
      <title>{"Cashew"}</title>
      <g fill="none" stroke={props.fill || "#000"} strokeLinecap="round">
        <path d="m2.766 13.259.346.251A5.613 5.613 0 0 1 5.4 17.473l.095.911a1.33 1.33 0 0 0 1.691 1.14 2 2 0 0 0 1.443-2.028l-.163-3.08a4.771 4.771 0 0 0-2.177-3.758l-.303-.195a2.787 2.787 0 0 0-3.324.225 1.639 1.639 0 0 0 .104 2.571Zm.934-.91a7.8 7.8 0 0 1 3 5.195M14.982 5.441l-.27.332a5.613 5.613 0 0 0-1.184 4.42l.144.905a1.33 1.33 0 0 1-1.339 1.539 2 2 0 0 1-1.918-1.586l-.64-3.018a4.771 4.771 0 0 1 1.13-4.192l.243-.267a2.787 2.787 0 0 1 3.268-.643 1.639 1.639 0 0 1 .566 2.51Zm-1.139-.638A7.8 7.8 0 0 0 12.29 10.6M13.573 15.728l.4-.153a5.613 5.613 0 0 0 3.235-3.235l.327-.856a1.33 1.33 0 0 1 1.929-.663 2 2 0 0 1 .868 2.332l-.954 2.934a4.771 4.771 0 0 1-3.076 3.065l-.342.11a2.787 2.787 0 0 1-3.153-1.077 1.639 1.639 0 0 1 .766-2.457Zm.667 1.122a7.8 7.8 0 0 0 4.242-4.243" />
      </g>
    </svg>
  );
}
