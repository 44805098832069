import { useLocalStorage } from "../../hooks/useLocalStorage";
import User from "./UserModel";

export const useUser = () => {
  const { setItem } = useLocalStorage();

  const addUser = (user: User) => {
    setItem("user", JSON.stringify(user));
  };

  const removeUser = () => {
    setItem("user", "");
  };

  return { addUser, removeUser };
};

export function requestHeader() {
  // Définis le header des requetes
  const token = localStorage.getItem("sig-pacofide-access");
  if (token) {
    return { Authorization: `Bearer ${token.trim()}` };
  } else {
    return {};
  }
}
