import BaseModel from "./BaseModel";

export default class Filiere extends BaseModel {
  component?: any;
  value: string;

  constructor(id: number, name: string, value: string, component?: any) {
    super(id, name);
    this.component = component;
    this.value = value;
  }

  static default(): Filiere {
    return new Filiere(0, "Tout", "");
  }

  static toSelectOption(values: Filiere[]) {
    return values.map((value) => {
      return {
        value: value.value,
        label: value.name,
      };
    });
  }
}
