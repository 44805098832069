import BaseModel from "./BaseModel";

export default class Village extends BaseModel {
  longitude: number;
  latitude: number;
  projects_list: any;

  constructor(
    id: number,
    name: string,
    longitude: number,
    latitude: number,
    projects_list: any,
  ) {
    super(id, name);
    this.longitude = longitude;
    this.latitude = latitude;
    this.projects_list = projects_list;
  }

  static tout(): Village {
    return new Village(0, "Tout", 0, 0, []);
  }

  static fromJson(json: any): Village {
    return new Village(
      json.id,
      json.name,
      json.longitude,
      json.latitude,
      json.projects_list,
    );
  }

  static toJson(value: Village): any {
    return {
      ...super.toJson(value),
      longitude: value.longitude,
      latitude: value.latitude,
    };
  }

  static toJsonList(values: Village[]): any {
    return values.map((value) => Village.toJson(value));
  }
}
