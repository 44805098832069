import { useState, useEffect } from "react";
import { getProject } from "../../services/api/data/project_services";
import Project from "../../models/Project_model";
import SelectComponent from "./SelectComponent";
import Filiere from "../../models/Filiere_model";
import { defaultFilieres, defaultRegions } from "../constants";
import Intervention from "../../models/Intervention_model";
import { getInterventions } from "../../services/api/data/intervention_services";
import { getDepartement } from "../../services/api/data/departement_services";
import Departement, { Commune } from "../../models/Departement_model";
import Pole from "../../models/Pole_model";
import { getPole } from "../../services/api/data/pole_services";
import { getVillages } from "../../services/api/data/village_services";
import Village from "../../models/Village_model copy";
import Thematic from "../../models/Thematic";
import { useLocalStorageState } from "./Navbar";

export default function IndicateurSuivi(props: any) {
  const {
    isOpen,
    onClose,
    actionSetMarkers,
    regionLayerSetter,
    selectedFiliere,
    setCommuneName,
  } = props;

  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<any>();
  const [interventions, setInterventions] = useState<Intervention[]>([]);
  const [thematics, setThematics] = useState<Thematic[]>([]);
  const [selectedThematic, setSelectedThematic] = useState<any>();

  const [communes, setCommunes] = useState<Commune[]>([]);
  const [selectedCommune, setSelectedCommune] = useState<any>();
  const [villages, setVillages] = useState<Village[]>([]);

  const [poles, setPoles] = useState<Pole[]>([]);
  const [selectedPole, setSelectedPole] = useState<any>();
  const [departements, setDepartements] = useState<Departement[]>([]);
  const [selectedDepartement, setSelectedDepartement] = useState<any>();
  const [region, setRegion] = useState<"pole" | "departement">();
  const [selectedIntervention, setSelectedIntervention] = useState<any>();

  const notNull = (value: any) => {
    return value !== null && value !== undefined;
  };

  const getVillagesWithParams = (params: URLSearchParams = new URLSearchParams()) => {
    if (selectedProject) {
      params.append("project", selectedProject);
    }
    params.append("intervention", selectedIntervention);
    getVillages(params).then((response) => {
      setVillages(Village.fromJsonList(response.data.results));
    });
  };

  const hierarchyList = [
    setSelectedThematic,
    setSelectedIntervention,
    setSelectedProject,
    setRegion,
    setSelectedDepartement,
    setSelectedPole,
    setSelectedCommune,
  ];

  /**
   * When a choice is made on a select the following selects
   * in the form must be reset
   * This method aims to reset the associated state variables
   * @param fromIndex Index when to start the reset from
   */
  const resetVariables = (fromIndex: number) => {
    if (fromIndex < 3) {
      // Reset variables when any of the first three variables are re-initialized
      setVillages([]);
    }
    for (let index = fromIndex; index < hierarchyList.length; index++) {
      const setter = hierarchyList[index];
      setter(undefined);
    }
  };

  const changeVariable = (value: any, setterIndex: number) => {
    hierarchyList[setterIndex](value);
    resetVariables(setterIndex + 1);
  };

  useEffect(() => {
    Thematic.fetchData(new URLSearchParams([["filiere", selectedFiliere]])).then(
      (response: any) => {
        setThematics(Thematic.fromJsonList(response.data.results));
      },
    );
    resetVariables(0);
    setSelectedThematic(null);
  }, [selectedFiliere]);

  useEffect(() => {
    if (notNull(selectedThematic)) {
      // Interventions to be selected are only PACOFIDE interventions
      let params = new URLSearchParams([["project", "1"]]);
      if (selectedThematic) params.append("thematic", selectedThematic);
      if (selectedFiliere) params.append("filiere", selectedFiliere);
      getInterventions(params).then((response) => {
        setInterventions(Intervention.fromJsonList(response.data.results));
      });
    }
  }, [selectedThematic]);

  useEffect(() => {
    if (selectedIntervention) {
      let params = new URLSearchParams([["intervention", selectedIntervention]]);
      getProject(params).then((response: any) => {
        setProjects(Project.fromJsonList(response.data.results));
      });
    }
  }, [selectedIntervention]);

  useEffect(() => {
    if (notNull(selectedProject)) {
      let params = new URLSearchParams([["intervention", selectedIntervention]]);
      if (selectedProject) {
        params.append("project", selectedProject);
      }
      getPole(params).then((response) => {
        setPoles(Pole.fromJsonList(response.data.results));
      });
      getDepartement(params).then((response) => {
        setDepartements(Departement.fromJsonList(response.data.results));
      });
    }
  }, [selectedProject]);

  useEffect(() => {
    if (region) getVillagesWithParams();
    regionLayerSetter(region);
  }, [region]);

  useEffect(() => {
    setCommuneName(communes.find((item) => item.id == selectedCommune)?.slug);
  }, [selectedCommune]);

  const changeParentRegion = (parentType: "pole" | "departement", value: any) => {
    let params = new URLSearchParams([[parentType, value]]);
    getVillagesWithParams(params);
    Commune.fetchData(params).then((response: any) => {
      setCommunes(Commune.fromJsonList(response.data.results));
    });
  };

  useEffect(() => {
    if (region && (notNull(selectedDepartement) || notNull(selectedPole))) {
      changeParentRegion(region, region == "pole" ? selectedPole : selectedDepartement);
    }
  }, [selectedDepartement, selectedPole]);

  useEffect(() => {
    if (actionSetMarkers) {
      actionSetMarkers(villages);
    }
  }, [villages]);

  useEffect(() => {
    if (selectedCommune && selectedIntervention && notNull(selectedProject)) {
      let params = new URLSearchParams([["commune", selectedCommune]]);
      getVillagesWithParams(params);
    } else if (selectedCommune == 0) {
      let params = new URLSearchParams([
        [region, region == "pole" ? selectedPole : selectedDepartement],
      ]);
      getVillagesWithParams(params);
    }
  }, [selectedCommune]);

  return (
    <div
      className={`overlay-inner absolute left-[40px] top-[150px] ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="tracking-inner absolute">
        <div
          className="lc-modal absolute left-0 top-0 w-fit sm:w-[400px] bg-black/50 p-5 z-[999] transition-pacofide"
          data-lc-target="tracking"
        >
          <button
            className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-white text-2xl z-[2] cursor-pointer rounded-full transition-pacofide"
            data-lc-target="#tracking"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ fill: "currentColor" }}
            >
              <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
            </svg>
          </button>
          <div className="space-y-12">
            <form className="relative text-white">
              <h3 className="flex items-center justify-center space-x-4 text-base font-medium">
                Indicateurs de Suivi
              </h3>
              <div className="space-y-6 mt-6 overflow-y-auto overflow-x-hidden max-h-[300px] md:max-h-none">
                <div className="sm:space-y-6 sm:block sm:w-[370px]">
                  <label className="items-center sm:flex">
                    <span className="block sm:min-w-[150px] text-sm">Thématique</span>

                    <SelectComponent
                      options={[
                        Thematic.default(),
                        ...Thematic.toSelectOption(thematics),
                      ]}
                      onChange={(value) => changeVariable(value, 0)}
                      customPlaceHolder="Sélectionner une thématique"
                      className="w-full"
                      value={selectedThematic}
                    />
                  </label>

                  {notNull(selectedThematic) && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[150px] text-sm">
                        Interventions
                      </span>

                      <SelectComponent
                        options={Intervention.toSelectOption(interventions)}
                        onChange={(value) => changeVariable(value, 1)}
                        customPlaceHolder="Sélectionner une intervention"
                        value={selectedIntervention}
                      />
                    </label>
                  )}

                  {notNull(selectedIntervention) && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[150px] text-sm">Projet</span>

                      <SelectComponent
                        options={[
                          Project.default(),
                          ...Project.toSelectOption(projects),
                        ]}
                        onChange={(value) => changeVariable(value, 2)}
                        customPlaceHolder="Sélectionner un projet"
                        value={selectedProject}
                      />
                    </label>
                  )}

                  {notNull(selectedProject) && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[150px] text-sm">Unité géo.</span>

                      <SelectComponent
                        options={defaultRegions}
                        onChange={(value) => changeVariable(value, 3)}
                        customPlaceHolder="Sélectionner un type de région"
                        value={region}
                      />
                    </label>
                  )}

                  {region === "pole" && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[150px] text-sm">Pole</span>

                      <SelectComponent
                        options={Pole.toSelectOption(poles)}
                        onChange={(value) => changeVariable(value, 5)}
                        customPlaceHolder="Sélectionner un pole"
                        value={selectedPole}
                      />
                    </label>
                  )}

                  {region === "departement" && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[150px] text-sm">
                        Departement
                      </span>

                      <SelectComponent
                        options={Departement.toSelectOption(departements)}
                        onChange={(value) => changeVariable(value, 4)}
                        customPlaceHolder="Sélectionner un département"
                        value={selectedDepartement}
                      />
                    </label>
                  )}

                  {(notNull(selectedPole) || notNull(selectedDepartement)) && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[150px] text-sm">Commune</span>

                      <SelectComponent
                        options={[
                          Commune.default(),
                          ...Commune.toSelectOption(communes),
                        ]}
                        onChange={(value) => changeVariable(value, 6)}
                        customPlaceHolder="Sélectionner un département"
                        value={selectedCommune}
                      />
                    </label>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
