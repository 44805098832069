export default class Carte {
  id: number;
  name: string;
  description: string;
  image: string;
  geojson: any;

  constructor(
    id: number,
    name: string,
    description: string = "",
    image: string = "",
    geojson: any = null,
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.image = image;
    this.geojson = geojson;
  }

  static tout(): Carte {
    return new Carte(0, "Tout");
  }

  static fromJson(json: any): Carte {
    return new Carte(json.pk, json.nom, json.name, json.image, json.geojson);
  }

  static fromJsonList(json: any[]): Carte[] {
    return json.map((value) => Carte.fromJson(value));
  }

  static toJson(value: Carte): any {
    return {
      id: value.id,
      name: value.name,
      description: value.description,
      image: value.image,
      geojson: value.geojson,
    };
  }

  static toJsonList(values: Carte[]): any {
    return values.map((value) => Carte.toJson(value));
  }
}
