import React, { useEffect, useState } from "react";
import { useCustomEventListener } from "react-custom-events";

const FileLoader = (props) => {
  const { eventToListen, setDownloading, className } = props;
  // State to keep track of download progress
  const [progress, setProgress] = useState(0);

  useCustomEventListener(eventToListen, (progress) => {
    setProgress(progress);
    if (progress === 100) {
      setDownloading(false);
    }
  });

  // // Function to simulate file download progress
  // const simulateFileDownload = () => {
  //   const interval = setInterval(() => {
  //     // Simulate progress update
  //     setProgress((prevProgress) => {
  //       const newProgress = prevProgress + 10;
  //       return newProgress >= 100 ? 100 : newProgress;
  //     });

  //     // Stop interval when download completes
  //     if (progress === 100) {
  //       clearInterval(interval);
  //     }
  //   }, 1000);
  // };

  // useEffect(() => {
  //   simulateFileDownload();
  // }, []);

  const radius = 10;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = ((100 - progress) / 100) * circumference;

  return (
    <div className={className}>
      <svg width="24" height="24">
        {/* Background Circle */}
        <circle
          cx="12"
          cy="12"
          r={radius}
          fill="transparent"
          stroke="#e6e6e6"
          strokeWidth="2"
        />
        {/* Progress Circle */}
        <circle
          cx="12"
          cy="12"
          r={radius}
          fill="transparent"
          stroke="#007bff"
          strokeWidth="3"
          strokeDasharray={circumference}
          strokeDashoffset={progressOffset}
          transform="rotate(-90 12 12)"
        />
        {/* Progress Text */}
        {/* <text x="24" y="24" textAnchor="middle" fontSize="10" fill="#007bff">
          {progress}%
        </text> */}
      </svg>
    </div>
  );
};

export default FileLoader;
