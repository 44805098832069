import any_file_logo from "./any-file-icon.svg";
import pdf_logo from "./pdf-file-icon.png";
import ms_word_logo from "./docx-file-icon.svg";
import ms_excel_logo from "./xls-file-icon.svg";

const ChooseFileIcon = (cardData) => {
  let fileNamePart = cardData.filename.split(".");
  let fileExtension = fileNamePart[fileNamePart.length - 1];

  let logo = any_file_logo;

  if (fileExtension === "pdf") {
    logo = pdf_logo;
  } else if (fileExtension === "doc" || fileExtension === "docx") {
    logo = ms_word_logo;
  } else if (
    fileExtension === "csv" ||
    fileExtension === "xls" ||
    fileExtension === "xlsx"
  ) {
    logo = ms_excel_logo;
  } else {
    logo = any_file_logo;
  }
  return logo;
};

export default ChooseFileIcon;
