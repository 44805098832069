export default class User {
  id: number;
  nom?: string;
  email?: string;

  constructor(id: number, email?: string, nom?: string) {
    this.id = id;
    this.nom = nom;
    this.email = email;
  }
}
