import Module from "./models/ModuleModel";
import departements from "../shapes/departement.json";
import poles from "../shapes/poles.json";
import {
  BeakerIcon,
  DocumentIcon,
  MapIcon,
  MapPinIcon,
} from "@heroicons/react/24/solid";
import { ICON_MARKER_AMENAGEMENT } from "./constantJs";
import Carte from "../models/Carte_model";
import StreetViewSvg from "./components/StreetViewSvg";
import SateliteViewSvg from "./components/SateliteViewSvg";
import Filiere from "../models/Filiere_model";
import PineapleSVG from "./components/PineapleSvg";
import CashewSVG from "./components/CashewSvg";

export const DATE_FORMAT_OPTIONS = {
  weekday: "short",
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

export interface ChartDataTypeInterface {
  id: string;
  label: string;
  geojson: object;
  // List of divisions names where the project intervene
  interventionSet: any;
}

const interventionsModule = new Module(
  "Interventions",
  "/mappingmarker",
  <BeakerIcon className="w-6 h-6" />,
);
const cartephysiqueModule = new Module(
  "Cartes physiques",
  "/mapping",
  <BeakerIcon className="w-6 h-6" />,
);

export const MODULES: Module[] = [
  new Module("Accueil", "/", <BeakerIcon className="w-6 h-6" />),
  new Module("Mapping", "/mapping", <MapIcon className="w-6 h-6" />, true, [
    interventionsModule,
    cartephysiqueModule,
  ]),
  new Module("Rapports", "/reports", <DocumentIcon className="w-6 h-6" />),
  new Module("Cartothèque", "/maplibrary", <MapPinIcon className="w-6 h-6" />),
];

export interface ChartDataTypeInterface {
  id: string;
  label: string;
  geojson: object;
  // List of divisions names where the project intervene
  interventionSet: any;
}

export const TERRITORY_DIVISION_TYPES: ChartDataTypeInterface[] = [
  {
    id: "departement",
    label: "Départements",
    geojson: departements,
    interventionSet: ["Alibori", "Borgou", "Collines"],
  },
  {
    id: "pole",
    label: "Poles",
    geojson: poles,
    interventionSet: ["PDA1", "PDA2", "PDA4"],
  },
];

export interface MapViewInterface {
  id: string;
  illustration: string;
  component?: any;
  viewName: string;
  tileLayerInfos: any;
}

const SATELITE_VIEW = {
  attribution:
    "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
  url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
};

const DEFAULT_VIEW = {
  attribution:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
};

export const MAP_VIEWS: MapViewInterface[] = [
  {
    id: "1",
    illustration: "/street.jpg",
    component: <StreetViewSvg />,
    viewName: "Rues",
    tileLayerInfos: DEFAULT_VIEW,
  },
  {
    id: "2",
    illustration: "/sat.jpg",
    component: <SateliteViewSvg />,
    viewName: "Satellite",
    tileLayerInfos: SATELITE_VIEW,
  },
];

// Fixme: We should use an Enum here
export const defaultFilieres = [
  // new Filiere(0, "Tout"),
  new Filiere(1, "Ananas", "pineapple", PineapleSVG),
  new Filiere(2, "Anacarde", "cashew", CashewSVG),
];

// Mapping
export const weightStyle = { weight: 2 };
export const Layers = [
  {
    name: "Départements",
    layer: departements,
    style: weightStyle,
    api: "departements",
    selectValue: "departement",
  },
  {
    name: "Poles",
    layer: poles,
    style: weightStyle,
    api: "poles",
    selectValue: "pole",
  },
];
export const TABLES = [{}];

export interface LayerControlInterface {
  id: any;
  endPoint: string;
  label: string;
  iconMarker: any;
}

export const LAYER_CONTROLS: LayerControlInterface[] = [
  {
    id: 1,
    endPoint: "/villages",
    label: "Village",
    iconMarker: ICON_MARKER_AMENAGEMENT,
  },
];

export const MAP_FEATURE_TYPES = [
  {
    id: "1",
    correspondingTypes: ["HIMO", "Aménagement en entreprise"],
  },
  {
    id: "2",
    correspondingTypes: ["MAGASINS"],
  },
  {
    id: "3",
    correspondingTypes: ["Retenue d'eau"],
  },
  {
    id: "4",
    correspondingTypes: ["Boutique"],
  },
];

export const const_cartes: Carte[] = [
  new Carte(1, "first map", "first map", "https://picsum.photos/200/300", departements),
  // new Carte(2, "second map", "this is the first map", "https://picsum.photos/200/300"),
  // new Carte(3, "third map", "this is the first map", "https://picsum.photos/200/300"),
  // new Carte(4, "fourth map", "this is the first map", "https://picsum.photos/200/300"),
  // new Carte(1, "first map", "this is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first map", "https://picsum.photos/200/300"),
  // new Carte(2, "second map", "this is the first map", "https://picsum.photos/200/300"),
  // new Carte(3, "third map", "this is the first map", "https://picsum.photos/200/300"),
  // new Carte(4, "fourth map", "this is the first map", "https://picsum.photos/200/300"),
  // new Carte(1, "first map", "this is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first map", "https://picsum.photos/200/300"),
  // new Carte(2, "second map", "this is the first map", "https://picsum.photos/200/300"),
  // new Carte(3, "third map", "this is the first map", "https://picsum.photos/200/300"),
  // new Carte(4, "fourth map", "this is the first map", "https://picsum.photos/200/300"),
  // new Carte(1, "first map", "this is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first map", "https://picsum.photos/200/300"),
  // new Carte(2, "second map", "this is the first map", "https://picsum.photos/200/300"),
  // new Carte(3, "third map", "this is the first map", "https://picsum.photos/200/300"),
  // new Carte(4, "fourth map", "this is the first map", "https://picsum.photos/200/300"),
  // new Carte(1, "first map", "this is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first mapthis is the first map", "https://picsum.photos/200/300"),
  // new Carte(2, "second map", "this is the first map", "https://picsum.photos/200/300"),
  // new Carte(3, "third map", "this is the first map", "https://picsum.photos/200/300"),
  // new Carte(4, "fourth map", "this is the first map", "https://picsum.photos/200/300"),
];

export const defaultRegions = [
  { value: "0", label: "Tout" },
  { value: "pole", label: "Pole" },
  { value: "departement", label: "Departement" },
];
