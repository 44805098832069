import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Filler,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  BarController,
  CategoryScale,
} from "chart.js";
// import ChartDataLabels from "chartjs-plugin-datalabels";
import { IconButton } from "@mui/material";
import { emitCustomEvent } from "react-custom-events";
import RegionSelector from "./RegionSelector";
import { useLocation, useOutletContext } from "react-router-dom";
import ChartRenderer from "./ChartsRenderer";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import {
  TERRITORY_DIVISION_TYPES,
  ChartDataTypeInterface as DivisionTypeInterface,
} from "../../core/constants";
import SelectComponent from "../../core/components/SelectComponent";
import { getProject } from "../../services/api/data/project_services";
import Project from "../../models/Project_model";
import { getYear } from "../../services/api/data/year_services";
import { getSelectionValues } from "../../core/helpers";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  ArcElement,
  BarController,
  Filler,
  Tooltip,
  Legend,
  // ChartDataLabels,
);

type ContextType = { filterParams: any; selectedDivTypeName: any };

export default function Graph() {
  /**
   * In the following base code:
   * - divisionType is a specific type of territory division (departments or poles)
   * - division is a specific division of a given type (Ex: Alibori for departments and PDA1 for poles)
   */
  const location = useLocation();
  const currentIntervention = location.state?.intervention;
  const [filiere, intervention, setFiliere] = useOutletContext<any>();

  const [selectedDivisionType, setSelectedDivisionType] = useState(
    TERRITORY_DIVISION_TYPES[0],
  );
  const [selectedDivTypeName, setDivisionTypeName] = useState(selectedDivisionType.id);
  const [filterParams, setFilterParams] = useState<URLSearchParams>(
    new URLSearchParams([["intervention", currentIntervention?.id]]),
  );
  const [statsUnit, setStatsUnit] = useState(TERRITORY_DIVISION_TYPES[0].id);
  const [projects, setProjects] = useState<Project[]>([]);
  const [years, setYears] = useState<number[]>([]);

  const changeDivisionType = (newDivisionType: DivisionTypeInterface) => {
    setSelectedDivisionType(newDivisionType);
    setDivisionTypeName(newDivisionType.id);
    setFilterParams(new URLSearchParams([["intervention", currentIntervention?.id]]));
  };

  const reInit = () => {
    emitCustomEvent("re-init");
    setSelectedDivisionType(selectedDivisionType);
    setStatsUnit(selectedDivisionType.id);
    setFilterParams(new URLSearchParams([["intervention", currentIntervention?.id]]));
  };

  const filterByDivision = (divisionName: string) => {
    const params = new URLSearchParams([
      [selectedDivisionType.id, divisionName],
      ["intervention", currentIntervention?.id],
    ]);
    setFilterParams(params);
    setStatsUnit("commune");
  };

  useEffect(() => {
    // setStatsUnit("commune");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  useEffect(() => {
    if (intervention) {
      const filters = new URLSearchParams([["intervention", intervention.id]]);
      if (selectedDivisionType)
        filters.append(
          selectedDivisionType.id,
          filterParams.get(selectedDivisionType.id) || "",
        );
      setFilterParams(filters);
    }
  }, [intervention]);

  const buildParameter = (label: string) => {
    const params = new URLSearchParams();
    params.append(statsUnit, label);
    setFilterParams(params);
    return params;
  };

  // useEffect(() => {
  //
  //   getProject().then((response) => {
  //     setProjects([Project.default(), ...Project.fromJsonList(response.data.results)]);
  //   });
  //   getYear().then((response) => {
  //     setYears(response.data);
  //   });
  // }, []);

  let defaulRegionOptions = TERRITORY_DIVISION_TYPES.map((value) => {
    return {
      value: value.id,
      label: value.label,
    };
  });

  const changeRegion = (element: any) => {
    let newDivType: any = TERRITORY_DIVISION_TYPES.find(
      (elm: any) => elm.id === element,
    );
    if (newDivType) {
      changeDivisionType(newDivType);
    }
    setStatsUnit(element);
  };

  return (
    <>
      <div className="px-4 h-screen w-screen sm:flex justify-between h-full pt-[67px]">
        <div className="relative h-[70%] sm:h-full flex-grow">
          {TERRITORY_DIVISION_TYPES.map(
            (regionsData) =>
              selectedDivTypeName === regionsData.id && (
                <RegionSelector
                  key={regionsData.id}
                  filterMethod={filterByDivision}
                  regions={regionsData.geojson}
                  interventionSet={regionsData.interventionSet}
                />
              ),
          )}
          <div className="absolute top-1 z-40 ">
            <IconButton onClick={reInit}>
              <ArrowPathIcon className="w-10" />
            </IconButton>
            <label className="items-center w-full">
              <SelectComponent
                value={getSelectionValues(selectedDivisionType.id, defaulRegionOptions)}
                options={defaulRegionOptions}
                onChange={changeRegion}
                customPlaceHolder="Sélectionner un type de région"
                className="w-full"
              />
            </label>
          </div>
        </div>
        <div className="p-4 h-full flex-grow" style={{ maxWidth: "80%" }}>
          <ChartRenderer
            filterParams={filterParams}
            selectedDivTypeName={selectedDivTypeName}
            statsUnitProps={statsUnit}
            params={filterParams}
            buildParameter={buildParameter}
            intervention={intervention}
          />
        </div>
      </div>
    </>
  );
}
